import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  FundAmountByCategory,
  FundAmountByGrant,
  FundDetails,
  FundsOpenSearch,
} from '@fund-base/types/funds/funds.types';
import {
  fundAmountByCategoriesApiUrl,
  fundAmountByCategoriesFirstApiUrl,
  fundAmountByGrantsApiUrl,
  fundAmountByGrantsFirstApiUrl,
  fundDetailsApiUrl,
  fundFirstVisitorDetailsApiUrl,
  fundingExpertsListApiUrl,
  fundsSearchAfterApiUrl,
  fundsSearchApiUrl,
  fundsSearchFiltersCountApiUrl,
  fundsSearchHistoryApiUrl,
  fundsSearchVisitorsApiUrl,
  fundVisitorDetailsApiUrl,
  fundVisitorNumberApiUrl,
  lastMonthFundsApiUrl,
  lastMonthFundsCountApiUrl,
  lastMonthSearchApiUrl,
  shareFundApiUrl,
  sourceFundsApiUrl,
} from '@fund-base/constants/funds/funds.constants';
import {
  FiltersSearch,
  FundsFilter,
  FundsSortingOption,
  SearchHistory,
  SearchHistoryQuery,
  SearchQuery,
} from '@fund-base/types/funds/funds-search.types';
import { FundingExpert, FundingExpertsSearchFilters } from '@fund-base/types/funding-expert/expert.types';

@Injectable({ providedIn: 'root' })
export class FundsService {
  constructor(private httpClient: HttpClient) {}

  private _stateVisitorFromWebViews = new BehaviorSubject<number>(this.initialViewsValue());
  private _stateSorting = new BehaviorSubject<string>('Best Match');

  // get funds
  getFunds(
    filtersSearch?: FiltersSearch,
    page?: number,
    size?: number
  ): Observable<{
    fundsList: FundsOpenSearch[];
    filtersCount: FundsFilter[];
    fundsCount: number;
    searchQuery: SearchQuery;
    usersSearchHistory: SearchHistory[];
    page: number;
    size: number;
  }> {
    // console.log('hit to get funds');
    const sortingOption = this._getSortingOption();
    return this.httpClient.post<{
      fundsList: FundsOpenSearch[];
      fundsCount: number;
      filtersCount: FundsFilter[];
      searchQuery: SearchQuery;
      usersSearchHistory: SearchHistory[];
      page: number;
      size: number;
    }>(fundsSearchApiUrl, {
      filtersSearch: { ...filtersSearch, sortBy: sortingOption.value },
      page: page,
      size: size,
    });
  }

  // get funds
  getFundsFiltersCount(query?: SearchQuery): Observable<{
    filtersCount: FundsFilter[];
  }> {
    return this.httpClient.post<{
      filtersCount: FundsFilter[];
    }>(fundsSearchFiltersCountApiUrl, {
      query: query,
    });
  }

  // get funds
  getFundsVisitors(
    filtersSearch?: FiltersSearch,
    page?: number,
    size?: number
  ): Observable<{
    fundsList: FundsOpenSearch[];
    filtersCount: FundsFilter[];
    fundsCount: number;
    searchQuery: SearchQuery;
    page: number;
    size: number;
  }> {
    return this.httpClient.post<{
      fundsList: FundsOpenSearch[];
      fundsCount: number;
      filtersCount: FundsFilter[];
      searchQuery: SearchQuery;
      page: number;
      size: number;
    }>(fundsSearchVisitorsApiUrl, {
      filtersSearch: filtersSearch,
      page: page,
      size: size,
    });
  }

  // get funds
  getNumberFundsVisitors(): Observable<any> {
    return this.httpClient.get(fundVisitorNumberApiUrl);
  }

  // get funds
  getSourceFunds(): Observable<any> {
    return this.httpClient.get(sourceFundsApiUrl);
  }

  // get funds Through History
  getFundsThroughHistory(
    filtersSearch?: FiltersSearch,
    searchQuery?: SearchHistoryQuery
  ): Observable<{
    fundsList: FundsOpenSearch[];
    filtersCount: FundsFilter[];
    fundsCount: number;
    searchQuery: SearchQuery;
  }> {
    // console.log('hit get funds through history');
    return this.httpClient.post<{
      fundsList: FundsOpenSearch[];
      fundsCount: number;
      filtersCount: FundsFilter[];
      searchQuery: SearchQuery;
    }>(fundsSearchHistoryApiUrl, {
      filtersSearch,
      searchQuery,
    });
  }

  // get funds Through History
  getFundsThroughMonthHistory(
    filtersSearch?: FiltersSearch,
    searchQuery?: SearchHistoryQuery
  ): Observable<{
    fundsList: FundsOpenSearch[];
    filtersCount: FundsFilter[];
    fundsCount: number;
    searchQuery: SearchQuery;
  }> {
    return this.httpClient.post<{
      fundsList: FundsOpenSearch[];
      fundsCount: number;
      filtersCount: FundsFilter[];
      searchQuery: SearchQuery;
    }>(lastMonthSearchApiUrl, {
      filtersSearch,
      searchQuery,
    });
  }

  // get more funds
  getMoreFunds(
    filtersSearch?: FiltersSearch,
    page?: number,
    size?: number
  ): Observable<{
    fundsList: FundsOpenSearch[];
    filtersCount: FundsFilter[];
    fundsCount: number;
    searchQuery: SearchQuery;
    usersSearchHistory: SearchHistory[];
    page: number;
    size: number;
  }> {
    // console.log('hit get more funds');
    const sortingOption = this._getSortingOption();
    return this.httpClient.post<{
      fundsList: FundsOpenSearch[];
      fundsCount: number;
      filtersCount: FundsFilter[];
      searchQuery: SearchQuery;
      usersSearchHistory: SearchHistory[];
      page: number;
      size: number;
    }>(fundsSearchApiUrl, {
      filtersSearch: { ...filtersSearch, sortBy: sortingOption.value },
      page: page,
      size: size,
      sort: sortingOption.value,
    });
  }

  // get fund by id
  getFundById(id?: string): Observable<{
    fundsDetails?: FundDetails;
  }> {
    return this.httpClient.get<{ fundsDetails?: FundDetails }>(`${fundDetailsApiUrl}/${id}`);
  }

  getVisitorFundById(id?: string): Observable<{
    fundsDetails?: FundDetails;
  }> {
    return this.httpClient.get<{ fundsDetails?: FundDetails }>(`${fundVisitorDetailsApiUrl}/${id}`);
  }

  getVisitorFirstFundById(id?: string): Observable<{
    fundsDetails?: FundDetails;
  }> {
    return this.httpClient.get<{ fundsDetails?: FundDetails }>(`${fundFirstVisitorDetailsApiUrl}/${id}`);
  }

  // get fund by id
  getFundAmountByCategories(id?: string): Observable<FundAmountByCategory[]> {
    return this.httpClient.get<any>(`${fundAmountByCategoriesApiUrl}/${id}`);
    // return of([
    //   {catId: 1008, amount: 200},
    //   {catId: 1013, amount: 450},
    //   {catId: 1026, amount: 250},
    //   {catId: 1029, amount: 300},
    //   {catId: 1030, amount: 500}
    // ]);
  }

  // get fund by id
  getFirstFundAmountByCategories(id?: string): Observable<FundAmountByCategory[]> {
    return this.httpClient.get<any>(`${fundAmountByCategoriesFirstApiUrl}/${id}`);
  }

  // get fund by id
  getFundAmountByGrants(id?: string): Observable<FundAmountByGrant[]> {
    return this.httpClient.get<any>(`${fundAmountByGrantsApiUrl}/${id}`);
    // return of([
    //   {grant: '1', amount: 200},
    //   {grant: '2', amount: 450},
    //   {grant: '3', amount: 250},
    //   {grant: '4', amount: 300},
    //   {grant: '5', amount: 500}
    // ]);
  }

  getFirstFundAmountByGrants(id?: string): Observable<FundAmountByGrant[]> {
    return this.httpClient.get<any>(`${fundAmountByGrantsFirstApiUrl}/${id}`);
    // return of([
    //   {grant: '1', amount: 200},
    //   {grant: '2', amount: 450},
    //   {grant: '3', amount: 250},
    //   {grant: '4', amount: 300},
    //   {grant: '5', amount: 500}
    // ]);
  }

  // get funding experts
  getFundingExperts(
    filters?: FundingExpertsSearchFilters,
    pagination?: number
  ): Observable<{ fundingExpertsList: FundingExpert[]; totalCount: number }> {
    return this.httpClient.post<{
      fundingExpertsList: FundingExpert[];
      totalCount: number;
    }>(fundingExpertsListApiUrl, {
      filters,
      pagination,
    });
  }

  get stateVisitorView$() {
    return this._stateVisitorFromWebViews.asObservable();
  }

  setstateVisitorView(view: number): void {
    this._stateVisitorFromWebViews.next(view);
  }

  // get last month funds count
  getLastMonthFundsCount(): Observable<{ newFundsCount?: number }> {
    return this.httpClient.get<{
      newFundsCount?: number;
    }>(lastMonthFundsCountApiUrl);
  }

  // get last month funds count
  getLastMonthFunds(): Observable<any> {
    return this.httpClient.get<any>(lastMonthFundsApiUrl);
  }

  // get funding experts
  shareFund(body?: any, language?: string): Observable<{}> {
    return this.httpClient.post<{
      body: any;
      language: string;
    }>(shareFundApiUrl, {
      body,
      language,
    });
  }

  initialViewsValue(): number {
    const fundViewsFromWeb = localStorage.getItem('funds');
    if (!fundViewsFromWeb) {
      return 0;
    }
    return Number(fundViewsFromWeb);
  }

  get stateSorting$() {
    return this._stateSorting.asObservable();
  }

  setstateSorting(option: string): void {
    this._stateSorting.next(option);
  }

  _getSortingOption(): FundsSortingOption {
    const sortingByState = sessionStorage.getItem('sortBy');

    if (sortingByState) {
      if (sortingByState === 'highest-match') {
        return { value: 'highest-match', text: 'Highest Match' };
      } else {
        return { value: 'last-updated', text: 'Last Updated' };
      }
    } else {
      sessionStorage.setItem('sortBy', 'highest-match');
      return { value: 'highest-match', text: 'Highest Match' };
    }
  }
}
